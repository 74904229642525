<template>
    <div>
        <!-- Filters -->
        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col cols="6" md="2">
                        <label>
                            Season <small class="text-danger" v-if="serverErrors && serverErrors.season_id">{{ serverErrors.season_id[0] }}</small>
                        </label>
                        <b-form-select
                            size="sm"
                            v-model="season_id"
                            @change="changeSeason()"
                            :disabled="seasons.length <= 1"
                        >
                            <b-form-select-option :value="null" v-if="seasons.length > 1">Select...</b-form-select-option>
                            <b-form-select-option
                                v-for="season in seasons"
                                :key="season.id"
                                :value="season.id"
                            >
                                {{ season.startYear }}/{{ season.endYear }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                    <b-col
                        cols="12"
                        md="2"
                        class="mb-md-0 mb-2"
                        v-for="(toSelect, index) in selections"
                        :key="toSelect.level"
                    >
                        <label>
                            {{ toSelect.label }} <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{ serverErrors.location_id[0] }}</small>
                        </label>
                        <b-form-select
                            size="sm"
                            v-model="toSelect.model"
                            @change="changeFilters(toSelect.model, index)"
                        >
                            <b-form-select-option :value="null">Select...</b-form-select-option>
                            <b-form-select-option
                                v-for="location in toSelect.locations"
                                :key="location.id"
                                :value="location"
                            >
                                {{ location.name.toUpperCase() }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>

                    <b-col cols="6" md="2">
                        <label>
                            Sale # <small class="text-danger" v-if="serverErrors && serverErrors.number">{{ serverErrors.number[0] }}</small>
                        </label>
                        <b-form-select
                            size="sm"
                            v-model="number"
                        >
                            <b-form-select-option :value="null">Select...</b-form-select-option>
                            <b-form-select-option
                                v-for="(sale, index) in sales"
                                :key="index"
                                :value="sale"
                            >
                                {{ sale }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                    <b-col cols="6" md="2" class="pt-2">
                        <b-button
                            variant="outline-success"
                            size="sm"
                            @click="generateReport()"
                        >
                            <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                            <span class="text-nowrap">Generate</span>
                            <feather-icon icon="DownloadCloudIcon" class="ml-1"/>
                        </b-button>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <!-- ./Filters -->

    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
        BSpinner, BBadge,
    } from 'bootstrap-vue'

    import store from '@/store'
    import { ref, onUnmounted, onMounted } from '@vue/composition-api'
    import vSelect from 'vue-select'
    import villageSalesStoreModule from '@/views/cromis/reports/village_sale/villageSalesStoreModule'
    import axios from '@axios'
    
    export default {
        props: {},
        components: {
            BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
            BForm, BFormGroup,
            BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
            BSpinner, BBadge,
            ValidationObserver, ValidationProvider,
        },
        directives: {},

        setup(props, context){
            const dataForm = ref(null)
            const myModal = ref(null)
            const saving = ref(false)
            const serverErrors = ref({
                season_id: null,
                location_id: null,
                number: null
            })
            const selections = ref([])
            const formSelections = ref([])
            const levels = ref(null)
            const children = ref([])
            const seasons = ref([])
            const season_id = ref(null)
            const location_id = ref(null)
            const number = ref(null)

            const sales = ref([])

            const CROMIS_STORE_MODULE_NAME = 'cromis-villageSale'

            // Register module
            if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, villageSalesStoreModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
            })

            onMounted(async () => {
                await store.dispatch('cromis-villageSale/levels')
                        .then(response => {
                            levels.value = response.data.levels[0]

                            selections.value.push({
                                level: levels.value.name.toLowerCase(),
                                model: null,
                                label: levels.value.name,
                                child: levels.value.children.length > 0 ? levels.value.children[0].name.toLowerCase() : null,
                                locations: [],
                            })

                            formSelections.value.push({
                                level: levels.value.name.toLowerCase(),
                                model: null,
                                label: levels.value.name,
                                child: levels.value.children.length > 0? levels.value.children[0].name.toLowerCase() : null,
                                locations: [],
                            })

                            let list = levels.value.children

                            while(list.length > 0){
                                children.value.push(list[0])
                                if(list[0].children > 0){
                                    selections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: list[0].children[0].name.toLowerCase(), locations: [] })
                                    formSelections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: list[0].children[0].name.toLowerCase(), locations: [] })
                                }
                                else{
                                    selections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: null, locations: [] })
                                    formSelections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: null, locations: [] })
                                }

                                list = list[0].children
                            }
                        })
                        .catch(error => {
                            console.log('got error', error)
                        })

                await store.dispatch('cromis-villageSale/locations')
                            .then(response => {
                                populateSelections(response.data.locations)
                            })
                            .catch(error => {
                                console.log('got error', error)
                            })

                await store.dispatch('cromis-villageSale/seasons')
                            .then(response => {
                                seasons.value = response.data.seasons
                                if(seasons.value.length === 1){
                                    season_id.value = seasons.value[0].id
                                    changeSeason()
                                }
                            })
                            .catch(error => {
                                console.log('got error', error)
                            })
            })

            const populateSelections = (locations) => {
                for(let i = 0; i < locations.length; i++){
                    for(let x = 0; x < selections.value.length; x++){
                        if(locations[i].level.name.toLowerCase() === selections.value[x].level){
                            selections.value[x].locations.push(locations[i])

                            if(x === 0){
                                formSelections.value[x].locations.push(locations[i])
                            }
                        }
                    }
                }
            }

            const changeSeason = async () => {
                await store.dispatch('cromis-villageSale/sales', { season_id: season_id.value, report: true })
                            .then(response => {
                                sales.value = response.data
                            })
                            .catch(error => {
                                console.log('got error', error)
                            })
            }

            const changeFilters = async (location, index) => {
                if(!location){
                    for(let i = index + 1; i < selections.value.length; i++){
                        selections.value[i].locations.splice(0)
                        selections.value[i].model = null
                    }

                    if(index > 0){
                        location_id.value = selections.value[index - 1].model.id
                    }
                    else{
                        location_id.value = null
                    }
                }
                else{
                    if(selections.value[index + 1]){
                        selections.value[index + 1].locations.splice(0)
                    }

                    location.children.map((child) => {
                        selections.value[index + 1].locations.push(child)
                    })

                    if(location.children.length > 0){
                        selections.value[index + 1].model = null
                    }

                    location_id.value = selections.value[index].model.id
                }
            }

            const generateReport = async () => {
                let hasError = false
                serverErrors.value = {
                    season_id: null,
                    location_id: null,
                    number: null
                }

                if(!season_id.value){
                    hasError = true
                    serverErrors.value.season_id = ['Specify season']
                }

                if(!location_id.value){
                    hasError = true
                    serverErrors.value.location_id = ['Select villaage']
                }

                if(!number.value){
                    hasError = true
                    serverErrors.value.number = ['Sale required']
                }

                if(!hasError){
                    saving.value = true

                    await window.open(`${ axios.defaults.baseURL }/reports/village_sales?season_id=${ season_id.value }&location_id=${ location_id.value }&number=${ number.value }`, '_blank')
                    saving.value = false
                }
            }

            return {
                dataForm,
                myModal,
                saving,
                serverErrors,
                selections,
                formSelections,
                levels,
                children,
                seasons,
                sales,
                season_id,
                location_id,
                number,
                changeFilters,
                generateReport,
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>